<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Notifications</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Update your personal notification settings.</span>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md">
          <h5 class="overflow-ellipsis">Disable all notifications <span class="label label-sm label-danger label-inline">Caution</span></h5>
          <span>This option will block all notifications from all devices in all companies.</span>
        </div>
        <div class="col-auto mt-3 my-md-auto">
          <span :class="['switch', { 'switch-danger': document.disable_all_notifications === true }]">
            <label>
              <input
                type="checkbox"
                :checked="document.disable_all_notifications === true"
                @click.prevent="disableNotifications($event.target.checked)"
                name="select"
              >
              <span />
            </label>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '@/libs/classes/user';

export default {
  props: {
    resource: {
      type: User,
      default: null,
    },
  },
  data () {
    return {
      isLoadingForm: false,

      document: (this.resource ? this.resource.clone() : null),
    };
  },

  methods: {
    async disableNotifications (disabled) {
      if (typeof disabled !== 'boolean') {
        return;
      }

      if (disabled === false) {
        try {
          const response = await this.$ws.patch(`${User.uri}/me`, {
            body: [{
              action: 'set_field',
              field: 'disable_all_notifications',
              value: disabled,
            }],
          });

          this.resource.merge(response);
          this.$set(this, 'document', this.resource.clone());
        } catch (e) {
          this.$errors.handle(e, { ui_element: true });
          console.error(e);
        }
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Disable all notifications',
        message: 'Are you sure that you want to disable all notifications?',
        buttonText: 'I\'m sure',
        async onSubmit () {
          const response = await vm.$ws.patch(`${User.uri}/me`, {
            body: [{
              action: 'set_field',
              field: 'disable_all_notifications',
              value: disabled,
            }],
          });

          vm.resource.merge(response);
          vm.$set(vm, 'document', vm.resource.clone());
        },
      });
    },
  },
};
</script>
